import "../_sass/main.scss";
// import "plyr/plyr.js";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import particlesJs from "particles.js";
// import mapboxgl from "mapbox-gl";
import "waypoints/lib/noframework.waypoints.js";
import $ from "jquery";
// var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
document.addEventListener("DOMContentLoaded", () => {
  console.log("Hello Bulma!");
  $("#videoModalBackground, #videoModalClose").on("click", function() {
    $("#videoModal").removeClass("is-active");
    mainPlayer.stop();
  });

  $("#video_button").on("click", function() {
    $("#videoModal").addClass("is-active");
  });

  // document.getElementById("videoModalBackground").addEventListener("click", function(event) {
  //   document.getElementById("videoModal").classList.remove("is-active");
  // });
  $(".navbar-menu .navbar-item").on("click", function() {
    $(".navbar-item.is-active").removeClass("is-active");
    // $(this).addClass("is-active");
    // console.log("element clicked", $("#" + $(this).attr("to")).position().top);

    $("html, body").animate({ scrollTop: $("#" + $(this).attr("to")).position().top - 56 + "px" });
  });

  $("#navbar-burger").on("click", function() {
    if ($(this).hasClass("is-active")) {
      $(this).removeClass("is-active");
      $("#navbar-menu").removeClass("is-active");
    } else {
      $(this).addClass("is-active");
      $("#navbar-menu").addClass("is-active");
    }
  });

  var array = ["main_hero2", "about", "why", "features", "devices", "how", "contact"];

  for (let i = 0; i < array.length; i++) {
    let point = array[i];
    console.log(document.getElementById(point));

    new Waypoint({
      element: document.getElementById(point),
      offset: "56px",
      handler: function(direction) {
        console.log(direction, point, $(this.element).attr("id"));
        if (direction == "up") {
          // $(".navbar-item.is-active").removeClass("is-active");
          // $(".navbar-item[to=" + $(this.element).attr("id") + "]").addClass("is-active");
          $(".navbar-item.is-active").removeClass("is-active");
          $(".navbar-item[to=" + array[i == 0 ? array.length - 2 : i - 1]).addClass("is-active");
        } else if (direction == "down") {
          $(".navbar-item.is-active").removeClass("is-active");
          $(".navbar-item[to=" + point).addClass("is-active");
        }
      }
    });
  }

  // $(".section").waypoint({
  //   handler: function(direction) {
  //     console.log("reached about");
  //   }
  // });

  // var waypoint = new Waypoint({
  //   element: document.getElementById('basic-waypoint'),
  //   handler: function () {
  //     notify('Basic waypoint triggered')
  //   }
  // })

  const howPlayer = new Plyr("#howPlayer");
  const mainPlayer = new Plyr("#mainPlayer");

  mapboxgl.accessToken = "pk.eyJ1IjoibW91bmlyeW91bmVzIiwiYSI6ImNqaXJwbWFmZzB4aWIzdm1zMjBkNHBrMm8ifQ.SGGyA7li01DbU8uphvRtRA";
  var map = new mapboxgl.Map({
    container: "contactmap",
    style: "mapbox://styles/mapbox/dark-v9",
    center: [20, 20],
    zoom: 3
  });
  // var mymap = L.map("mapid").setView([51.505, -0.09], 13);

  // L.tileLayer("https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}", {
  //   attribution:
  //     'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
  //   maxZoom: 18,
  //   id: "mapbox.streets",
  //   accessToken: "your.mapbox.access.token"
  // }).addTo(mymap);

  particlesJS(
    "particles-js",
    {
      particles: {
        number: {
          value: 20,
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: "#ffffff"
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000"
          },
          polygon: {
            nb_sides: 5
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 10,
          random: true,
          anim: {
            enable: false,
            speed: 80,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 300,
          color: "#ffffff",
          opacity: 0.4,
          width: 2
        },
        move: {
          enable: true,
          speed: 2,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: false,
            mode: "repulse"
          },
          onclick: {
            enable: false,
            mode: "push"
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 800,
            line_linked: {
              opacity: 1
            }
          },
          bubble: {
            distance: 800,
            size: 80,
            duration: 2,
            opacity: 0.8,
            speed: 3
          },
          repulse: {
            distance: 400,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true
    },
    function() {
      console.log("callback - particles.js config loaded");
    }
  );
});
